@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");

body {
  font-family: "open sans";
  font-size: 1em;
}

.main-header-app {
  background-color: #814911;
}

.navbar-menu-items {
  width: 9rem;
  justify-content: space-between;
}

.main-carousel-container {
  margin: auto;
  width: 60%;
  margin-top: 3rem;
}

.main-brand-name {
  color: #ffffff !important;
}

.main-nav-links {
  color: #ffffff !important;
}

.carousel-menu-link {
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.1rem 2rem;
  border-radius: 12px;
}

.main-contact-page {
  color: #ffffff;
  background: url("https://cdn.wallpapersafari.com/45/99/ZMo9r7.jpg") no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-size: cover;
  min-height: 43rem;
}

.cotact-card-container {
  display: flex;
  justify-content: space-around;
}

.contact-methods {
  display: flex;
  justify-content: space-around;
}

.main-about-page {
  background: url("https://plus.unsplash.com/premium_photo-1667066302681-2893297c9b6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8dmlsbGFnZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-size: cover;
  min-height: 43rem;
}

.about-page-content {
  padding: 13rem 2rem;
  color: #ffffff;
  font-size: 1.3rem;
}

.card {
  position: relative;
  width: 220px;
  height: 320px;
  background: #814911;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  border-radius: 15px;
  margin-top: 11rem;
  min-height: 20rem;
}

.card::before,
.card::after {
  position: absolute;
  content: "";
  width: 20%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  background-color: #814911;
  transition: all 0.5s;
}

.card::before {
  top: 0;
  right: 0;
  border-radius: 0 15px 0 100%;
}

.card::after {
  bottom: 0;
  left: 0;
  border-radius: 0 100% 0 15px;
}

.main-product-container {
  border: 1px solid #814911;
  border-radius: 2rem;
  padding: 2rem;
  margin: 0 1rem;
  width: 16rem;
}

.main-drinks-container {
  display: flex;
  justify-content: space-around;
  margin: 2rem 4rem;
  flex-wrap: wrap;
  row-gap: 3rem;
}

.product-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.product-price {
  font-size: 0.8rem;
  font-weight: bold;
}

.product-description {
  font-size: 0.8rem;
  margin-top: 1rem;
}

@media screen and (max-width: 430px) {
  .contact-methods {
    flex-direction: column;
    margin-left: 5.5rem;
  }
  .main-carousel-container {
    width: 100%;
    margin-top: 14rem;
  }
}
